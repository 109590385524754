/* eslint-disable prettier/prettier */
import { combineReducers } from 'redux'
import render from './render/render'
import sidebarShow from './sidebarShow/sidebarShow'

const reducers = combineReducers({
  render,
  sidebarShow,
})

export default reducers
