/* eslint-disable prettier/prettier */
let initialState = true

const sidebarShow = (state = initialState, action) => {
  switch (action.type) {
    case 'set':
      return action.payload
    default:
      return state
  }
}

export default sidebarShow
